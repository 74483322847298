
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const favicon16 = require('../dist/favicon/favicon-16x16.png')
const favicon32 = require('../dist/favicon/favicon-32x32.png')
const favicon64 = require('../dist/favicon/favicon-64x64.png')

function SEO({ lang, title, description }) {

  const metaDescription = description ? description : "Modernizing businesses through tailored software solutions.";

  return (
    <StaticQuery query={detailsQuery} render={data => {
        return (
          <Helmet
            htmlAttributes={{ lang }} title={title} titleTemplate={title === 'Auxidus Technologies' ? 'Auxidus Technologies' : `%s | ${data.site.siteMetadata.title}`}
            link={[
              { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16 },
              { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32 },
              { rel: "icon", type: "image/png", sizes: "64x64", href: favicon64 },
              { rel: "shortcut icon", type: "image/png", href: favicon64 }
            ]}
          >
            <meta charSet="utf-8" />            
            <meta name="description" content={metaDescription} />
            <meta name="image" content={favicon64} />
            <meta property="og:url" content={"https://www.auxidus.com"} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={favicon64} />
            <meta property="og:type" content={'website'} />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}
SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
