
import React,  { useState, useEffect, useContext } from "react"
import styled, { createGlobalStyle, keyframes, StyleSheetManager } from "styled-components"
import { Link } from "gatsby"
import { Colors } from '../constants'
import { navigate } from 'gatsby'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Collapse from '@material-ui/core/Collapse';
import DvrIcon from '@material-ui/icons/Dvr';
// import SendIcon from '@material-ui/icons/Send';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import AccountTreeIcon from '@material-ui/icons/AccountTree';
// import RoomServiceIcon from '@material-ui/icons/RoomService';
// import { Forum } from '@material-ui/icons'
import LanguageIcon from '@material-ui/icons/Language';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import SEO from './seo'
import EventNoteIcon from '@material-ui/icons/EventNote';
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { openPopupWidget } from "react-calendly"

import { AppBar, Drawer, Container, Toolbar, IconButton, Button, makeStyles, Modal, Grid, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const LogoImg = require('../dist/images/headerIcon.png');

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0AA7CF',
    },
  },
});
const useStyles = makeStyles({
    paper: {
      background: '#FFFFFF',
      color: 'black'
    },
    root: {
      width: 360,
      backgroundColor: '#FFFFFF'
    },
    nested: {
      paddingLeft: 35,
    }
});

export default ({ padTop, title, children, description }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [scroll, setScroll] = useState(false);
  // const [openServicesMenu, setOpenServicesMenu] = useState(true);

  useEffect(() => { window.addEventListener("scroll", () => { setScroll(window.scrollY > 5) })}, []); 

  const styles = useStyles();
  

  return (    
    <ThemeProvider theme={theme}>
        <Wrapper>
          <GlobalStyle />
          <NavbarWrapper colorTransparent scroll={scroll} position={'fixed'}>
            <Container style={{position: 'relative'}}>


              <Navbar>
                <Link to="/"><Logo /></Link>
                <MenuButton onClick={() => setShowSidebar(true)}><MenuIcon /></MenuButton>
                <UncollapsedMenu>
                  <Button 
                    style={{ color: Colors.backgroundBlueLighter, fontWeight: 600 }}
                    onClick={()=> navigate('/')}
                  >
                    Home
                  </Button>
                  <Button 
                    style={{ color: Colors.backgroundBlueLighter, fontWeight: 600 }}
                    onClick={()=> navigate('/services/webapps')}
                  >
                    Web Applications
                  </Button>
                  <Button 
                    style={{ color: Colors.backgroundBlueLighter, fontWeight: 600 }}
                    onClick={()=> navigate('/services/websites')}
                  >
                    Website Development
                  </Button>
                  <Button 
                    variant={'outlined'} 
                    color="primary" 
                    style={{ color: Colors.backgroundBlueLighter, fontWeight: 600, color: Colors.auxidusBlue, marginLeft: '5px' }}
                    onClick={()=> navigate('/company/contact')}
                  >
                    Contact Us
                    </Button>
                </UncollapsedMenu>
                <ContactButton onClick={()=> navigate('/company/contact')}>
                  <ContactTypography variant={'button'}>CONSULTATION</ContactTypography>
                  <EventNoteIcon style={{color: Colors.auxidusBlue}} />
                </ContactButton>
              </Navbar>


            </Container>
          </NavbarWrapper>
          <Sidebar classes={{ paper: styles.paper }} anchor={'right'} open={showSidebar} onOpen={() => setShowSidebar(true)} onClose={() => setShowSidebar(!showSidebar)}>
              <List
                component="nav"
                className={styles.root}
              >
                <ListItem button onClick={()=> navigate('/company/contact')}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={()=> navigate('/services/webapps')}>
                      <ListItemIcon>
                        <DvrIcon />
                      </ListItemIcon>
                      <ListItemText primary="Web Applications" />
                    </ListItem>
                    <ListItem button onClick={()=> navigate('/services/websites')}>
                      <ListItemIcon>
                        <LanguageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Website Development" />
                </ListItem>
                {/* <ListItem button onClick={()=> navigate('/company/about')}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="About Us" />
                </ListItem> */}
                {/* <ListItem button onClick={()=> navigate('/company/blog')}>
                  <ListItemIcon>
                    <Forum />
                  </ListItemIcon>
                  <ListItemText primary="Blog" />
                </ListItem> */}
                {/* <ListItem button onClick={() => setOpenServicesMenu(!openServicesMenu)}>
                  <ListItemIcon>
                    <RoomServiceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Services" />
                  {openServicesMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItem> */}
                {/* <Collapse in={openServicesMenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={styles.nested} onClick={()=> navigate('/services/webapps')}>
                      <ListItemIcon>
                        <DvrIcon />
                      </ListItemIcon>
                      <ListItemText primary="Enterprise Web Applications" />
                    </ListItem>
                    <ListItem button className={styles.nested} onClick={()=> navigate('/services/websites')}>
                      <ListItemIcon>
                        <LanguageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Professional Web Development" />
                    </ListItem>
                    <ListItem button className={styles.nested} onClick={()=> navigate('/services/consultations')}>
                      <ListItemIcon>
                        <AccountTreeIcon />
                      </ListItemIcon>
                      <ListItemText primary="IT Infrastructure Consulting" />
                    </ListItem>
                  </List>
                </Collapse> */}
                <ListItem button onClick={()=> navigate('/company/contact')}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contact" />
                </ListItem>
              </List>
          </Sidebar>
          <StyledMain padTop={padTop}>
              <ActionButton title={title} onClick={() => openPopupWidget({
                url: 'https://calendly.com/info-auxidus/free-project-consultation'             
                })}>
                  <EventNoteIcon />
              </ActionButton>
              <SiteContent>
                <SEO title={title} description={description} />
                {children}
              </SiteContent>
              <Footer>
                <Separater />
                  <FooterPrimary>
                    <Container>
                      <Grid spacing={1} container style={{ paddingBottom: '25px', borderBottom: '1px solid lightgrey' }}>
                        <FooterGrid item xs={6} sm={3} type={'main'}>
                          <FooterColumn>
                            <FooterItem type={'title'}>Navigation</FooterItem>
                            <FooterItem onClick={()=> navigate('/')}>Home</FooterItem>
                            <FooterItem onClick={()=> navigate('/services/webapps')}>Web Applications</FooterItem>
                            <FooterItem onClick={()=> navigate('/services/websites')}>Website Development</FooterItem>
                            {/* <FooterItem onClick={()=> navigate('/services/consultations')}>Consultations</FooterItem> */}
                            <FooterItem onClick={()=> navigate('/company/contact')}>Contact Us</FooterItem>
                          </FooterColumn>
                        </FooterGrid>
                        <FooterGrid spacing={1} container xs={6} sm={9}>
                          <FooterGrid item xs={12} sm={3}>
                            <FooterColumn>
                              <FooterItem type={'title'} style={{paddingTop: '4px'}}>Legal</FooterItem>
                              <FooterItem onClick={()=> navigate('/company/legal/privacy')}>Privacy Policy</FooterItem>
                              <FooterItem onClick={()=> navigate('/company/legal/cookies')}>Cookie Policy</FooterItem>
                            </FooterColumn>
                          </FooterGrid>
                          <FooterGrid item xs={12} sm={9} type={'alt'}>
                            <FooterColumn>
                              <FooterItem type={'title'}  style={{paddingTop: '4px'}}>Social</FooterItem>
                              <FooterItem><OutboundLink href={`https://www.linkedin.com/company/auxidus-technologies/`} target="_blank" rel="norefferer">LinkedIn</OutboundLink></FooterItem>
                              <FooterItem><OutboundLink href={`https://www.facebook.com/Auxidous-Technologies-102768118200733`} target="_blank" rel="norefferer">Facebook</OutboundLink></FooterItem>
                              {/* <FooterItem>Twitter</FooterItem> */}
                            </FooterColumn>
                          </FooterGrid>
                        </FooterGrid>
                      </Grid>
                      <div style={{marginTop: '25px', textAlign: 'center'}}>
                        <FooterItem type={'title'} style={{ color: 'lightgrey', fontWeight: 'lighter' }}>© {new Date().getFullYear()}, Auxidus Technologies</FooterItem>
                      </div>
                    </Container>
                  </FooterPrimary>
              </Footer>
          </StyledMain>   
        </Wrapper>
        </ThemeProvider>
    
  )
}

const UncollapsedMenu = styled.div`
  position: absolute;
  right: 0;
  display: none;
  @media only screen and (min-width: 900px) {
    display: block;
  }
`;
const ContactTypography = styled(Typography)`
  font-size: 15px;
  line-height: 20px;
  margin-right: 5px;
  font-weight: bold;
  color: ${Colors.auxidusBlue};
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;
const wave = keyframes`
  0% { box-shadow: 0 0 0px 5px rgba(32, 32, 32, 0.5); }
  100% { box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0); }
`;
const ActionButton = styled.div`
  cursor: pointer;
  animation: ${wave} 3s alternate-reverse infinite;
  -webkit-animation: ${wave} 3s alternate-reverse infinite;
  -moz-animation: ${wave} 3s alternate-reverse infinite;
  -ms-animation: ${wave} 3s alternate-reverse infinite;
  -o-animation: ${wave} 3s alternate-reverse infinite;
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${Colors.auxidusBlue};
  bottom: 75px;
  right: 75px;
  z-index: 35;
  display: ${props => props.title === 'Contact Us' ? 'none' : 'flex'};
  svg {
    margin: auto;
    font-size: 30px;
    color: white;
  }
  opacity: 0.6;
  
  -webkit-transition: all .5s;
  -o-transition: all .5s;
   transition: all .5s;

  &:hover {
    svg {
      color: ${Colors.auxidusBlue};
    }
    background: #F3F3F3;
  }

  @media only screen and (max-width: 767.5px) {
    display: none;
  }
`;

const FooterGrid = styled(Grid)`
  text-align: ${props => props.type === 'alt' ? 'right' : 'left'};
  @media only screen and (max-width: 600px) {
    text-align: left;
  }
`;
const Separater = styled.div`
    background-color: #fefefe;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='12' viewBox='0 0 40 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.172L6.172 0h5.656L0 11.828V6.172zm40 5.656L28.172 0h5.656L40 6.172v5.656zM6.172 12l12-12h3.656l12 12h-5.656L20 3.828 11.828 12H6.172zm12 0L20 10.172 21.828 12h-3.656z' fill='%23666666' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: 10px;
    width: 100%;
`;
const FooterPrimary = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;
  background: #FAFAFA;
`;
const FooterColumn = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
  flex-direction: column;
`;
const FooterItem = styled(Typography)`
  font-weight: ${props => props.type === 'title' ? 'bold' : 'regular'};
  padding-bottom: ${props => props.type === 'title' ? '10px' : 'auto'};
  cursor: ${props => props.type !== 'title' ? 'pointer' : 'regular'};
  color: ${props => props.type === 'title' ? Colors.auxidusBlue : 'black'};
  &:hover {
    font-weight: ${props => props.type !== 'title' ? '500' : 'default'};
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;
const GlobalStyle = createGlobalStyle`
  *, html, body, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;
const SiteContent = styled.div`
  flex-grow: 1;
  height: 100%;
  display: block;
  position: relative;
`;
const Sidebar = styled(Drawer)`
  position: relative;
  overflow: hidden;
  color: ${Colors.backgroundDarkGray};
`;
const StyledMain = styled.main`
  padding-top: ${props => props.padTop ? '90px' : '0'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
  overflow: auto;
`;
const Logo = styled.div`
  height: 50px;
  width: 195px;
  background: url(${LogoImg}) no-repeat;
  background-size: cover;
  cursor: pointer;
`
const MenuButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  @media only screen and (min-width: 900px) {
    display: none;
  }
`
const ContactButton = styled(IconButton)`
  position: absolute;
  right: 35px;
  border: 1.5px solid ${Colors.auxidusBlue};
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 10px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (max-width: 500px) {
    border-color: transparent;
  }
`
const Wrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
  position: relative;
  display: block;
`
const NavbarWrapper = styled(AppBar)`
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
  height: 70px;
  background-color: ${props => props.scroll ? `rgba(255, 255, 255, 0.05)` : 'transparent'};
  background: ${props => props.scroll ? `rgba(255, 255, 255, 0.05)` : 'transparent'};
  backdrop-filter: ${props => props.scroll ? 'blur(5px)' : 'none'};
  -webkit-backdrop-filter: ${props => props.scroll ? 'blur(5px)' : 'none'};
  box-shadow: ${props => props.scroll ? `0px 2px 2px 0px rgba(0,0,0,0.35)` : 'none'};
`
const Navbar = styled(Toolbar)`
  height: 70px;
  min-height: 70px;
  padding: 0;
`
const Footer = styled.footer`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  background-color: ${Colors.backgroundWhite};
  overflow: hidden;
`

// import React from "react"
// import PropTypes from "prop-types"
// import { StaticQuery, graphql } from "gatsby"

// import Header from "../components/header"
// import "./layout.css"

// const Layout = ({ children }) => (
//   <StaticQuery
//     query={graphql`
//       query SiteTitleQuery {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => (
//       <>
//         <Header siteTitle={data.site.siteMetadata.title} />
//         <div
//           style={{
//             margin: `0 auto`,
//             maxWidth: 960,
//             padding: `0px 1.0875rem 1.45rem`,
//             paddingTop: 0,
//           }}
//         >
//           <main>{children}</main>
//           <footer>
//             © {new Date().getFullYear()}, Built with
//             {` `}
//             <a href="https://www.gatsbyjs.org">Gatsby</a>
//           </footer>
//         </div>
//       </>
//     )}
//   />
// )

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// export default Layout
