
const Colors = {
    backgroundBlack: '#000000',
    backgroundGray: '#111111',
    backgroundDarkerGray: '#070707',
    backgroundDarkGray: '#0E0E0E',
    backgroundLightGray: '#222222',
    backgroundBlue: '#050913',
    backgroundAccent: '#292929',
    textWhite: '#EEEEEE',
    textPrimaryGray: '#A4A4A4',
    textSecondaryGray: '#555555',
    auxidusBlue: '#0AA7CF',
    backgroundBlueLighter: '#0B3155'
}

export { Colors }